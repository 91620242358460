import compose from "recompose/compose";
import Search from "./WithOnSearch";
import Confirm from "./withOnConfirm";
import WithOnUser from "./user";
import Gradient from "./colors/gradient";
import Color from "./colors/color";
import Crop from "./crop";
const popupComponents = [
    {
        component: compose(Crop, Search, Confirm, Color, Gradient)
    },
    {
        component: WithOnUser,
        positions: [
            "web",
            "textsocial"
        ]
    }
];
export default popupComponents;
