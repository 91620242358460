import ClientModule from "@gqlapp/module-client-react";
import compose from "recompose/compose";
import Search from "./search";
import Country from "./country";
import Date from "./date";
import Time from "./time";
import Select from "./select";
import Menu from "./menu";
import Click from "./click";
import Toolbar from "./click/toolbar";
const popupComponents = [
    {
        component: compose(Search, Country, Date, Time, Select, Menu)
    },
    {
        component: compose(Click, Toolbar)
    }
];
export default new ClientModule({
    popupComponents
});
