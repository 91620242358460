import withProps from "recompose/withProps";
import { map } from "../helpers/config";
import withFormFilter from "../helpers/Filter";
import loadable from "next/dynamic";
import LinksCompose from "../helpers/LinksCompose";
const ListComponent = loadable(()=>import("../components/list/LinksView").then((c)=>c.default), {
    loadableGenerated: {
        webpack: ()=>[
                require.resolveWeak("../components/list/LinksView")
            ]
    }
});
export default LinksCompose({}, [
    withProps((param)=>{
        let { query  } = param;
        return {
            pageProps: {
                path: map({
                    path: "list"
                }),
                other: map({
                    path: "listpage",
                    page: ":page"
                }),
                query
            }
        };
    }),
    withFormFilter,
    // withProps(({ pageProps })=>debug({
    //   pageProps
    // })),
    withProps((param)=>{
        let { entities , setFieldValue  } = param;
        return {
            refetch: ()=>{
                try {
                    entities.refetch();
                } catch (e) {}
                try {
                    setFieldValue("page", 1);
                } catch (e1) {}
            }
        };
    })
])(ListComponent);
