import { withApollo } from "react-apollo";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import loadingProcess from "@gqlapp/base/utils/loading/loading";
/**
 *
 * @param client
 * @param loadbar
 * @param query
 * @constructor
 */ function QueryLoading(client, loadbar, query) {
    return async (variables, fetchPolicy)=>{
        return await loadingProcess(loadbar, async ()=>new Promise(async (resolve)=>{
                client.query({
                    query,
                    variables,
                    fetchPolicy: fetchPolicy || "cache-first"
                }).then((param)=>{
                    let { data: { res  }  } = param;
                    return resolve(res);
                }).catch(()=>resolve({}));
            }));
    };
}
export function withContentCallback(callback, func, QUERY, fetchPolicy) {
    return compose(withApollo, withProps((param)=>{
        let { client  } = param;
        let props = {};
        props[func] = async function(vars) {
            let res = await QueryLoading(client, {}, QUERY)(vars, fetchPolicy);
            return callback(res);
        };
        return props;
    }));
}
export default function preloadContents(action, QUERY, fetchPolicy) {
    return compose(withApollo, withProps((param)=>{
        let { client , onActions , loadbar  } = param;
        onActions[action] = async function(vars, first, last) {
            first && await first();
            let res = await QueryLoading(client, loadbar, QUERY)(vars, fetchPolicy);
            last && await last(res);
            return res;
        };
        return {
            onActions
        };
    }));
}
