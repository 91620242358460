"use strict";
module.exports = {
    "en": {
        "title": "Page",
        "list.title": "Page",
        "addons": {
            "search.placeholder": "Search addons",
            "google.sheet.placeholder": "Search Google Sheets"
        },
        "social": {
            "type.google_spreadsheet": "Google sheet",
            "column": {
                "name": "Social name",
                "type": "Add-ons type"
            }
        },
        "config": {
            "form.name": "Name Config",
            "google": {
                "config.title": "Spreadsheet config",
                "spreadsheet": {
                    "title": "Select Spreadsheet",
                    "groupName": "Spreadsheet",
                    "id": "Enter Spreadsheet ID",
                    "name": "Enter Spreadsheet Name",
                    "choose": "Choose spreadsheet ID",
                    "type": {
                        "label": "Spreadsheet",
                        "choose": "Choose Spreadsheet",
                        "input": "SpreadSheets ID"
                    }
                },
                "sheet": {
                    "groupName": "Sheet",
                    "name": "Enter sheet ID"
                }
            }
        },
        "apps": {
            "edit.label": "Edit this link from the @links page",
            "replace.label": "Edit link",
            "spotify.label": "Spotify",
            "tripadvisor.label": "Tripadvisor",
            "shopify.label": "Shopify",
            "wechat.label": "Wechat",
            "discord.label": "Discord",
            "apple_podcasts.label": "Apple podcasts",
            "viber.label": "Viber",
            "twitch.label": "Twitch",
            "bandcamp.label": "Bandcamp",
            "pandora.label": "Pandora",
            "tiki.label": "Tiki",
            "lazada.label": "Lazada",
            "booking.label": "Booking",
            "soundcloud.label": "Soundcloud",
            "shopee.label": "Shopee",
            "etsy.label": "Etsy",
            "taobao.label": "Taobao",
            "reddit.label": "Reddit",
            "airbnb.label": "Airbnb",
            "youtube.label": "Youtube",
            "instagram.label": "Instagram",
            "apple_music.label": "Apple music",
            "pinterest.label": "Pinterest",
            "linkedin.label": "Linkedin",
            "facebook.label": "Facebook",
            "messenger.label": "Messenger",
            "skype.label": "Skype",
            "telegram.label": "Telegram",
            "vimeo.label": "Vimeo",
            "line.label": "Line",
            "whatapp.label": "Whatapp",
            "tiktok.label": "Tiktok",
            "tumblr.label": "Tumblr",
            "kakaotalk.label": "Kakaotalk",
            "snapchat.label": "Snapchat",
            "zalo.label": "Zalo",
            "twitter.label": "Twitter",
            "threads.label": "Threads",
            "beatport.label": "Beatport",
            "amazon.label": "Amazon",
            "tidal.label": "Tidal",
            "ebay.label": "eBay",
            "weibo.label": "Weibo",
            "qq.label": "Qq",
            "youtube_music.label": "Youtube music",
            "deezer.label": "Deezer",
            "zing_mp3.label": "Zing mp3",
            "amazon_music.label": "Amazon music",
            "aliexpress.label": "Aliexpress",
            "google_maps.label": "Google maps",
            "klook.label": "Klook"
        },
        "page": {
            "save": "Save",
            "content.label": "Group one",
            "label": {
                "title": "Page title",
                "url": "Link page"
            },
            "title": {
                "path": "BIO page",
                "seo": "SEO Settings",
                "cropping": "Cropping",
                "create": "Create page",
                "label": "Page title",
                "placeholder": "For example: Page title"
            },
            "menu": {
                "preview": "Preview",
                "add": "Add bio",
                "faq": "FAQ",
                "list": "Bio list"
            }
        },
        "actions": {
            "cancel": "Cancel",
            "apply": "Apply",
            "confirm": "Confirm",
            "page.btn": {
                "active": "Publish",
                "inactive": "Unpublish"
            },
            "form": {
                "google.sheet.connect": "Google Sheet Connect",
                "add_new": "Add new"
            }
        },
        "notify": {
            "page": {
                "active": "Page: Are you sure you are suspending publication of this page?",
                "inactive": "Page: Are you sure you want to unpublish this page?"
            },
            "title": {
                "even": "List {{value}} page",
                "odd": "List {{value}} page"
            },
            "submitted": {
                "success": {
                    "title": "Success message",
                    "message": "Thank you for submitted"
                }
            }
        },
        "block": {
            "addnew": "New block",
            "add": "Add blocks",
            "save": "Save blocks",
            "image": {
                "option": {
                    "title": {
                        "label": "Option name",
                        "placeholder": "Enter option name"
                    },
                    "value": {
                        "label": "Option value",
                        "placeholder": "Enter option value"
                    },
                    "url": {
                        "label": "Image url",
                        "placeholder": "Enter image url"
                    },
                    "price": {
                        "label": "Price",
                        "placeholder": "Enter price"
                    },
                    "currency": {
                        "label": "Currency",
                        "title": "Currency"
                    }
                }
            },
            "animation": {
                "title": "Animation",
                "no": "No",
                "blink": "Blink",
                "tada": "Tada",
                "buzz": "Buzz",
                "shakeX": "Shake X",
                "shakeY": "Shake Y",
                "wobble": "Wobble",
                "rubber": "Rubber",
                "pulse": "Pulse",
                "heartBeat": "Heart Beat"
            },
            "apps": {
                "form": {
                    "link.label": "Paste Link",
                    "search.label": "Search Link in Xem.link",
                    "domain.label": "Select domain",
                    "linkType": {
                        "external": "Paste link",
                        "internal": "Select a Xem.Link"
                    }
                },
                "facebook.label": "Facebook",
                "youtube.label": "Youtube",
                "twitter.label": "Twitter",
                "tiktok.label": "Tiktok",
                "pinterest.label": "Pinterest",
                "instagram.label": "Instagram",
                "linkedin.label": "Linkedin",
                "telegram.label": "Telegram",
                "weibo.label": "Weibo",
                "tumblr.label": "Tumblr",
                "vimeo.label": "Vimeo",
                "twitch.label": "Twitch",
                "reddit.label": "Reddit",
                "messenger.label": "Messenger",
                "skype.label": "Skype",
                "viber.label": "Viber",
                "zalo.label": "Zalo",
                "line.label": "Line",
                "kakaotalk.label": "Kakaotalk",
                "discord.label": "Discord",
                "whatapp.label": "Whatapp",
                "snapchat.label": "Snapchat",
                "wechat.label": "Wechat",
                "qq.label": "Qq",
                "apple_music.label": "Apple Music",
                "youtube_music.label": "Youtube Music",
                "soundcloud.label": "Soundcloud",
                "tidal.label": "Tidal",
                "deezer.label": "Deezer",
                "spotify.label": "Spotify",
                "zing_mp3.label": "ZingMp3",
                "amazon_music.label": "Amazon Music",
                "pandora.label": "Pandora",
                "apple_podcasts.label": "Apple Podcasts",
                "beatport.label": "Beatport",
                "bandcamp.label": "Bandcamp",
                "amazon.label": "Amazon",
                "shopee.label": "Shopee",
                "shopify.label": "Shopify",
                "ebay.label": "eBay",
                "aliexpress.label": "AliExpress",
                "etsy.label": "Etsy",
                "taobao.label": "Taobao",
                "tiki.label": "Tiki",
                "lazada.label": "Lazada",
                "airbnb.label": "Airbnb",
                "booking.label": "Booking",
                "tripadvisor.label": "Tripadvisor",
                "klook.label": "Klook",
                "threads.label": "Threads",
                "google_maps.label": "Google Maps",
                "google_map.label": "Google Maps"
            },
            "field": {
                "label": "Field title",
                "style.label": "Style",
                "required.label": "Required field",
                "placement.label": "Placement",
                "submit.label": "Submit Button",
                "actions": {
                    "options": {
                        "label": "Option list",
                        "placeholder": "Option name",
                        "add": "Add Options"
                    }
                },
                "title": {
                    "label": "Title Form",
                    "placeholder": "Enter title form"
                },
                "address": {
                    "label": "Address",
                    "placeholder": "Enter title address"
                },
                "options": {
                    "label": "Option list",
                    "placeholder": "Enter option name"
                },
                "name": {
                    "label": "Full name",
                    "placeholder": "Enter fullname label"
                },
                "image": {
                    "label": "Select By Image",
                    "placeholder": "Select By Image"
                },
                "description": {
                    "label": "Description",
                    "placeholder": "Enter description"
                },
                "textfield": {
                    "label": "String",
                    "placeholder": "String"
                },
                "email": {
                    "label": "Email",
                    "placeholder": "Email"
                },
                "phone": {
                    "label": "Phone number",
                    "placeholder": "Phone number"
                },
                "dropdown": {
                    "label": "List Dropdown",
                    "placeholder": "List Dropdown"
                },
                "radio": {
                    "label": "Radio button",
                    "placeholder": "Radio button"
                },
                "checkbox": {
                    "label": "Checkbox",
                    "placeholder": "Enter checkbox label"
                },
                "list": {
                    "label": "List dropdown",
                    "placeholder": "Enter list label"
                },
                "country": {
                    "label": "Country",
                    "placeholder": "Preferred Country"
                },
                "number": {
                    "label": "Number",
                    "placeholder": "Enter number label"
                },
                "date": {
                    "label": "Date",
                    "placeholder": "Preferred date"
                },
                "time": {
                    "label": "Time",
                    "placeholder": "Preferred Time"
                },
                "promo": {
                    "label": "Promo code",
                    "placeholder": "Enter promo code label"
                },
                "textarea": {
                    "label": "Text",
                    "placeholder": "Text"
                }
            },
            "form": {
                "label": "Add Form",
                "title": "Add Form",
                "config": "Form config",
                "settings": {
                    "action": {
                        "google_sheet": "Google sheet",
                        "label.title": "Social api connect",
                        "value": {
                            "none": "None",
                            "google_sheet": "Google sheets"
                        }
                    }
                },
                "field": {
                    "title": "Add field",
                    "action.add": "Add Field"
                }
            },
            "social": {
                "title": "Social",
                "label": "Social"
            },
            "divider": {
                "title": "Divider",
                "label": "Divider",
                "indent": {
                    "state1": "Standard",
                    "state2": "Manual"
                },
                "content": {
                    "color": "Color",
                    "full": "Full width line",
                    "fading": "Line with translucent edges"
                }
            },
            "settings.form.name.label": "Select form config",
            "design": {
                "titleStyle": "Title style",
                "descriptionStyle": "Description style",
                "backgroundColor": "Background color",
                "style": "Style",
                "color": "Color",
                "input": {
                    "outline": "Outlined color",
                    "focus": "Focus color",
                    "error": "Error color",
                    "label": "Label color",
                    "placeholder": "Placeholder color",
                    "helperText": "Helper Text color"
                },
                "indent.label": "Indent",
                "radius.title": "Radius",
                "padding.title": "Padding",
                "margin.title": "Margin",
                "border.title": "Border",
                "transparency.title": "Transparency",
                "icon": {
                    "show": "Show icon",
                    "style": "Icon style",
                    "placement": "Icon placement",
                    "color": "Icon color"
                }
            },
            "button": {
                "action": {
                    "home": "Home",
                    "listBio": "List Bio",
                    "add": "Add Bio",
                    "faq": "FAQ",
                    "menu": "Menu"
                },
                "title": "Button",
                "font": {
                    "weight": {
                        "label": "Font weight",
                        "light": "Light",
                        "standard": "Standard",
                        "bold": "Bold"
                    },
                    "transform": {
                        "label": "Text transform",
                        "standard": "Standard",
                        "uppercase": "Uppercase"
                    },
                    "family": {
                        "label": "Fonts"
                    }
                },
                "update": {
                    "config": "Config",
                    "page": "Page",
                    "add": "Create Block",
                    "preview": "Preview",
                    "menu": "Menu",
                    "publish": "Publish"
                },
                "label": {
                    "text": "Text",
                    "avatar": "Avatar",
                    "divider": "Divider",
                    "button": "Button",
                    "carousel": "Image carousel",
                    "countdown": "Count down",
                    "messaging": "Messaging",
                    "social": "Social",
                    "media_text": "Media And Text",
                    "form": "Form",
                    "vcard": "Vcard",
                    "faq": "FAQ",
                    "html": "Html",
                    "music": "Music",
                    "video": "Video",
                    "map": "Maps",
                    "product": "Shop Product",
                    "page": "Add page"
                },
                "website": {
                    "label": "Website",
                    "placeholder": "https://"
                },
                "icon": {
                    "title": "Select icon"
                },
                "content": {
                    "label": "Button text",
                    "title": {
                        "title": "Button",
                        "label": "Button title",
                        "placeholder": "For example: button title",
                        "required": "You have not entered a title"
                    },
                    "description": {
                        "label": "Button description",
                        "placeholder": "For example: button description"
                    },
                    "action": {
                        "home": "Home",
                        "listBio": "List Bio",
                        "add": "Add Bio",
                        "faq": "FAQ",
                        "menu": "Menu",
                        "alignment": {
                            "top": "Top",
                            "center": "Center",
                            "bottom": "Bottom"
                        },
                        "group": {
                            "website": "Website",
                            "internal": "Choose internal page",
                            "call": "Enter your phone number",
                            "sms": "Enter your phone number",
                            "email": "Enter your email address",
                            "contact": "Personal information",
                            "block": "Block"
                        },
                        "value": {
                            "message": {
                                "label": "Text message",
                                "placeholder": "Text message"
                            },
                            "email": {
                                "button": "Send email",
                                "label": "Email",
                                "placeholder": "example@xem.link",
                                "subject.placeholder": "Subject"
                            },
                            "subject": {
                                "label": "Subject",
                                "placeholder": "Subject"
                            },
                            "nonePage": "- No page -",
                            "pageLoading": "Page loading ...",
                            "label": "Action",
                            "title": "Action Title",
                            "website.label": "Website",
                            "call.label": "Open: Call",
                            "internal.label": "Internal page",
                            "sms.label": "Send Sms",
                            "contact.label": "Save contact",
                            "block.label": "Block",
                            "gotoBlock": "Go to block",
                            "emailSignup": "Email signup",
                            "embeddedPage": "Embedded page"
                        },
                        "title": {
                            "action": "Action",
                            "internal": "Internal"
                        },
                        "label": {
                            "phone": "Phone number",
                            "title": "Action",
                            "website": "https://"
                        },
                        "placeholder": {
                            "phone": "Phone number",
                            "website": "https://"
                        },
                        "website.label": "Website",
                        "call.label": "Call",
                        "internal.label": "Internal page",
                        "sms.label": "Send Sms",
                        "email.label": "Send email",
                        "email.object.subject.placeholder": "Send email",
                        "contact": "Save contact",
                        "gotoBlock": "Go to block",
                        "emailSignup": "Email signup",
                        "embeddedPage": "Embedded page",
                        "new_window": "Open in new window"
                    }
                },
                "shadow": {
                    "title": "Shadow",
                    "x_offset_left": "X offset left",
                    "y_offset_left": "Y offset left",
                    "blur": "Blur",
                    "spread": "Spread",
                    "transparency": "Transparency",
                    "color": "Color",
                    "none": "No"
                },
                "radius": {
                    "label": "Radius",
                    "top_left": "Top left",
                    "top_right": "Top right",
                    "bottom_left": "Bottom left",
                    "bottom_right": "Bottom right"
                },
                "padding": {
                    "label": "Padding",
                    "top": "Padding top",
                    "bottom": "Padding bottom",
                    "left": "Padding left",
                    "right": "Padding right"
                },
                "margin": {
                    "label": "Margin",
                    "top": "Margin top",
                    "bottom": "Margin bottom",
                    "left": "Margin left",
                    "right": "Margin right"
                },
                "settings": {
                    "block": {
                        "name": {
                            "label": "Block name",
                            "placeholder": "Enter block name"
                        },
                        "description": "Naming a block simplifies its search in the archive and allows you to create links to it in other blocks",
                        "on": "Set block name",
                        "backgroundColor": "Background block color",
                        "hidden": "Hide block"
                    },
                    "sheet": {
                        "description": "Sheet settings description",
                        "on": "Set sheet name",
                        "name": {
                            "label": "Sheet name",
                            "placeholder": "Enter sheet name"
                        },
                        "spreadsheet_Id": {
                            "label": "Spreadsheet Id",
                            "placeholder": "Enter spreadsheet Id"
                        },
                        "client_email": {
                            "label": "Google client_email",
                            "placeholder": "Enter google client_email"
                        },
                        "private_key": {
                            "label": "Google private_key",
                            "placeholder": "Enter google private_key"
                        }
                    }
                }
            },
            "submit": {
                "FormSetting.label": "Conversion event code",
                "content.label": "Button text",
                "thankYou.label": "Page Thank You",
                "success": {
                    "label": "Thank you page.",
                    "popup": "Default popup",
                    "thank": "Another page",
                    "title": {
                        "label": "Success title",
                        "placeholder": "Enter success title"
                    },
                    "message": {
                        "label": "Success message",
                        "placeholder": "Enter success message"
                    }
                },
                "thankpage": {
                    "label": "Thank you URL",
                    "placeholder": "Enter thank you URL"
                },
                "event": {
                    "title": "Conversion: Facebook / TikTok / GA",
                    "no": "Not selected",
                    "completeRegistration": "Complete registration",
                    "purchase": "Purchase",
                    "lead": "Lead",
                    "submitForm": "Submit Form",
                    "completePayment": "Complete Payment"
                },
                "currency": {
                    "label": "Currency",
                    "placeholder": "Enter currency"
                },
                "google_uid": {
                    "label": "Google UID",
                    "placeholder": "For example: UA-86097XXX-1 hoặc G-XXXXXXX"
                },
                "google_ads_id": {
                    "label": "Google Ads ID",
                    "placeholder": "For example: AW-868647XXX"
                },
                "facebook_pixel": {
                    "label": "Facebook pixel ID",
                    "placeholder": "For example: 1540856564722XXX"
                },
                "google_tag_id": {
                    "label": "Google Tag Manager ID",
                    "placeholder": "For example: GTM-XAVNXXX"
                },
                "tiktok_pixel": {
                    "label": "TikTok Pixel ID",
                    "placeholder": "For example: BLSRA1AA1AAA1AAAAAXXX"
                },
                "purchase": {
                    "label": "Conversion value",
                    "placeholder": "For example: 600000"
                },
                "eventId": {
                    "label": "Google Ads conversion code",
                    "placeholder": "Enter the conversion ID (eg: 868686XXX)"
                },
                "eventLabel": {
                    "label": "Google Ads conversion label",
                    "placeholder": "For example: XUa3CJEMrocHZHxxMMM"
                },
                "title": {
                    "label": "Button title",
                    "placeholder": "For example: Button title"
                }
            },
            "html": {
                "label": "Html",
                "title": "Html"
            },
            "link": "Link",
            "hidden_block.label": "Hide block",
            "vertical_indent.label": "Vertical indent",
            "block_name.label": "Set block name",
            "according_to_schedule.label": "Show according to schedule",
            "days_of_the_week.label": "Show by days of the week",
            "action.label": "action",
            "transparency.label": "Transparency",
            "header_color.label": "Header color",
            "subtitle_color.label": "Subtitle color",
            "background_color.label": "Background color"
        },
        "form": {
            "search.placeholder": "Form search",
            "app": {
                "group": {
                    "all": "All",
                    "social": "Social networks",
                    "messaging": "Messaging",
                    "music": "Music",
                    "travel": "Travel",
                    "shopping": "Shopping"
                }
            },
            "forms": {
                "edit": "Edit form",
                "delete": "Delete form",
                "optional": "Optional",
                "addons": {
                    "add": "Add-ons new",
                    "edit": "Edit add-on",
                    "delete": "Delete add-on"
                }
            },
            "display": {
                "label": "Display preview",
                "description.empty": "You don't have a description yet."
            },
            "page": {
                "content": {
                    "title": {
                        "placeholder": "For example: Page title",
                        "label": "Page title",
                        "description": "Displayed in the title of the browser tab and in search engine results"
                    },
                    "description": {
                        "placeholder": "For example: Meta-description",
                        "label": "Meta-description",
                        "description": "Displayed in search engine results under the link"
                    }
                },
                "html": {
                    "code": {
                        "label": "Code",
                        "placeholder": "Enter html code"
                    }
                },
                "edit": "Updated page",
                "create": "Created page",
                "config": {
                    "url": {
                        "label": "Url",
                        "placeholder": "Enter your alias"
                    },
                    "schema": {
                        "title": "Schema",
                        "label": "Schema",
                        "placeholder": "Enter your alias"
                    },
                    "title": "Config page",
                    "changeUrl": "Change Url",
                    "seo": "SEO settings",
                    "conversion": "Conversion event",
                    "qrcode": "QR code",
                    "background": "Page background",
                    "close": "Return pages",
                    "alignment": "Content alignment",
                    "duplicate": "Duplicate page",
                    "hide": "Hide page",
                    "inactive": "Hide page",
                    "active": "Publish page"
                }
            },
            "title": {
                "edit": "Edit page",
                "delete": "Delete page"
            },
            "btn": {
                "delete": "Delete page"
            }
        }
    },
    "vi": {
        "title": "Page",
        "list.title": "Page",
        "addons": {
            "search.placeholder": "Search addons",
            "google.sheet.placeholder": "Search Google Sheets"
        },
        "social": {
            "type.google_spreadsheet": "Google sheet",
            "column": {
                "name": "Social name",
                "type": "Add-ons type"
            }
        },
        "config": {
            "form.name": "Name Config",
            "google": {
                "config.title": "Spreadsheet config",
                "sheet": {
                    "groupName": "Sheet",
                    "name": "Enter sheet ID"
                },
                "spreadsheet": {
                    "title": "Select Spreadsheet",
                    "groupName": "Spreadsheet",
                    "id": "Enter Spreadsheet ID",
                    "name": "Enter Spreadsheet Name",
                    "choose": "Choose spreadsheet ID",
                    "type": {
                        "label": "Spreadsheet",
                        "choose": "Choose Spreadsheet",
                        "input": "SpreadSheets ID"
                    }
                }
            }
        },
        "apps": {
            "edit.label": "Edit this link from the @links page",
            "replace.label": "Edit link",
            "spotify.label": "Spotify",
            "tripadvisor.label": "Tripadvisor",
            "shopify.label": "Shopify",
            "wechat.label": "Wechat",
            "discord.label": "Discord",
            "apple_podcasts.label": "Apple podcasts",
            "viber.label": "Viber",
            "twitch.label": "Twitch",
            "bandcamp.label": "Bandcamp",
            "pandora.label": "Pandora",
            "tiki.label": "Tiki",
            "lazada.label": "Lazada",
            "booking.label": "Booking",
            "soundcloud.label": "Soundcloud",
            "shopee.label": "Shopee",
            "etsy.label": "Etsy",
            "taobao.label": "Taobao",
            "reddit.label": "Reddit",
            "airbnb.label": "Airbnb",
            "youtube.label": "Youtube",
            "instagram.label": "Instagram",
            "apple_music.label": "Apple music",
            "pinterest.label": "Pinterest",
            "linkedin.label": "Linkedin",
            "facebook.label": "Facebook",
            "messenger.label": "Messenger",
            "skype.label": "Skype",
            "telegram.label": "Telegram",
            "vimeo.label": "Vimeo",
            "line.label": "Line",
            "whatapp.label": "Whatapp",
            "tiktok.label": "Tiktok",
            "tumblr.label": "Tumblr",
            "kakaotalk.label": "Kakaotalk",
            "snapchat.label": "Snapchat",
            "zalo.label": "Zalo",
            "twitter.label": "Twitter",
            "threads.label": "Threads",
            "beatport.label": "Beatport",
            "amazon.label": "Amazon",
            "tidal.label": "Tidal",
            "ebay.label": "eBay",
            "weibo.label": "Weibo",
            "qq.label": "Qq",
            "youtube_music.label": "Youtube music",
            "deezer.label": "Deezer",
            "zing_mp3.label": "Zing mp3",
            "amazon_music.label": "Amazon music",
            "aliexpress.label": "Aliexpress",
            "google_maps.label": "Google maps",
            "klook.label": "Klook"
        },
        "page": {
            "save": "Save",
            "content.label": "Group one",
            "label": {
                "title": "Page title",
                "url": "Link page"
            },
            "title": {
                "path": "BIO page",
                "seo": "SEO Settings",
                "cropping": "Cropping",
                "create": "Create page",
                "label": "Page title",
                "placeholder": "For example: Page title"
            },
            "menu": {
                "preview": "Preview",
                "add": "Add bio",
                "faq": "FAQ",
                "list": "Bio list"
            }
        },
        "actions": {
            "cancel": "Cancel",
            "apply": "Apply",
            "confirm": "Confirm",
            "page.btn": {
                "active": "Publish",
                "inactive": "Unpublish"
            },
            "form": {
                "google.sheet.connect": "Google Sheet Connect",
                "add_new": "Add new"
            }
        },
        "notify": {
            "page": {
                "active": "Page: Are you sure you are suspending publication of this page?",
                "inactive": "Page: Are you sure you want to unpublish this page?"
            },
            "title": {
                "even": "List {{value}} page",
                "odd": "List {{value}} page"
            },
            "submitted": {
                "success": {
                    "title": "Success message",
                    "message": "Thank you for submitted"
                }
            }
        },
        "block": {
            "addnew": "New block",
            "add": "Add blocks",
            "save": "Save blocks",
            "image": {
                "option": {
                    "title": {
                        "label": "Option name",
                        "placeholder": "Enter option name"
                    },
                    "value": {
                        "label": "Option value",
                        "placeholder": "Enter option value"
                    },
                    "url": {
                        "label": "Image url",
                        "placeholder": "Enter image url"
                    },
                    "price": {
                        "label": "Price",
                        "placeholder": "Enter price"
                    },
                    "currency": {
                        "label": "Currency",
                        "title": "Currency"
                    }
                }
            },
            "animation": {
                "title": "Animation",
                "no": "No",
                "blink": "Blink",
                "tada": "Tada",
                "buzz": "Buzz",
                "shakeX": "Shake X",
                "shakeY": "Shake Y",
                "wobble": "Wobble",
                "rubber": "Rubber",
                "pulse": "Pulse",
                "heartBeat": "Heart Beat"
            },
            "apps": {
                "form": {
                    "link.label": "Paste Link",
                    "search.label": "Search Link in Xem.link",
                    "domain.label": "Select domain",
                    "linkType": {
                        "external": "Paste link",
                        "internal": "Select a Xem.Link"
                    }
                },
                "facebook.label": "Facebook",
                "youtube.label": "Youtube",
                "twitter.label": "Twitter",
                "tiktok.label": "Tiktok",
                "pinterest.label": "Pinterest",
                "instagram.label": "Instagram",
                "linkedin.label": "Linkedin",
                "telegram.label": "Telegram",
                "weibo.label": "Weibo",
                "tumblr.label": "Tumblr",
                "vimeo.label": "Vimeo",
                "twitch.label": "Twitch",
                "reddit.label": "Reddit",
                "messenger.label": "Messenger",
                "skype.label": "Skype",
                "viber.label": "Viber",
                "zalo.label": "Zalo",
                "line.label": "Line",
                "kakaotalk.label": "Kakaotalk",
                "discord.label": "Discord",
                "whatapp.label": "Whatapp",
                "snapchat.label": "Snapchat",
                "wechat.label": "Wechat",
                "qq.label": "Qq",
                "apple_music.label": "Apple Music",
                "youtube_music.label": "Youtube Music",
                "soundcloud.label": "Soundcloud",
                "tidal.label": "Tidal",
                "deezer.label": "Deezer",
                "spotify.label": "Spotify",
                "zing_mp3.label": "ZingMp3",
                "amazon_music.label": "Amazon Music",
                "pandora.label": "Pandora",
                "apple_podcasts.label": "Apple Podcasts",
                "beatport.label": "Beatport",
                "bandcamp.label": "Bandcamp",
                "amazon.label": "Amazon",
                "shopee.label": "Shopee",
                "shopify.label": "Shopify",
                "ebay.label": "eBay",
                "aliexpress.label": "AliExpress",
                "etsy.label": "Etsy",
                "taobao.label": "Taobao",
                "tiki.label": "Tiki",
                "lazada.label": "Lazada",
                "airbnb.label": "Airbnb",
                "booking.label": "Booking",
                "tripadvisor.label": "Tripadvisor",
                "klook.label": "Klook",
                "threads.label": "Threads",
                "google_maps.label": "Google Maps",
                "google_map.label": "Google Maps"
            },
            "field": {
                "label": "Field title",
                "style.label": "Style",
                "required.label": "Required field",
                "placement.label": "Placement",
                "submit.label": "Submit Button",
                "actions": {
                    "options": {
                        "label": "Option list",
                        "placeholder": "Option name",
                        "add": "Add Options"
                    }
                },
                "title": {
                    "label": "Title Form",
                    "placeholder": "Enter title form"
                },
                "address": {
                    "label": "Address",
                    "placeholder": "Enter title address"
                },
                "options": {
                    "label": "Option list",
                    "placeholder": "Enter option name"
                },
                "name": {
                    "label": "Full name",
                    "placeholder": "Enter fullname label"
                },
                "image": {
                    "label": "Select By Image",
                    "placeholder": "Select By Image"
                },
                "description": {
                    "label": "Description",
                    "placeholder": "Enter description"
                },
                "textfield": {
                    "label": "String",
                    "placeholder": "String"
                },
                "email": {
                    "label": "Email",
                    "placeholder": "Email"
                },
                "phone": {
                    "label": "Phone number",
                    "placeholder": "Phone number"
                },
                "dropdown": {
                    "label": "List Dropdown",
                    "placeholder": "List Dropdown"
                },
                "radio": {
                    "label": "Radio button",
                    "placeholder": "Radio button"
                },
                "checkbox": {
                    "label": "Checkbox",
                    "placeholder": "Enter checkbox label"
                },
                "list": {
                    "label": "List dropdown",
                    "placeholder": "Enter list label"
                },
                "country": {
                    "label": "Country",
                    "placeholder": "Preferred Country"
                },
                "number": {
                    "label": "Number",
                    "placeholder": "Enter number label"
                },
                "date": {
                    "label": "Date",
                    "placeholder": "Preferred date"
                },
                "time": {
                    "label": "Time",
                    "placeholder": "Preferred Time"
                },
                "promo": {
                    "label": "Promo code",
                    "placeholder": "Enter promo code label"
                },
                "textarea": {
                    "label": "Text",
                    "placeholder": "Text"
                }
            },
            "form": {
                "label": "Add Form",
                "title": "Add Form",
                "config": "Form config",
                "settings": {
                    "action": {
                        "google_sheet": "Google sheet",
                        "label.title": "Social api connect",
                        "value": {
                            "none": "None",
                            "google_sheet": "Google sheets"
                        }
                    }
                },
                "field": {
                    "title": "Add field",
                    "action.add": "Add Field"
                }
            },
            "social": {
                "title": "Social",
                "label": "Social"
            },
            "divider": {
                "title": "Divider",
                "label": "Divider",
                "indent": {
                    "state1": "Standard",
                    "state2": "Manual"
                },
                "content": {
                    "color": "Color",
                    "full": "Full width line",
                    "fading": "Line with translucent edges"
                }
            },
            "settings.form.name.label": "Select form config",
            "design": {
                "titleStyle": "Title style",
                "descriptionStyle": "Description style",
                "backgroundColor": "Background color",
                "style": "Style",
                "color": "Color",
                "input": {
                    "outline": "Outlined color",
                    "focus": "Focus color",
                    "error": "Error color",
                    "label": "Label color",
                    "placeholder": "Placeholder color",
                    "helperText": "Helper Text color"
                },
                "indent.label": "Indent",
                "radius.title": "Radius",
                "padding.title": "Padding",
                "margin.title": "Margin",
                "border.title": "Border",
                "transparency.title": "Transparency",
                "icon": {
                    "show": "Show icon",
                    "style": "Icon style",
                    "placement": "Icon placement",
                    "color": "Icon color"
                }
            },
            "button": {
                "action": {
                    "home": "Home",
                    "listBio": "List Bio",
                    "add": "Add Bio",
                    "faq": "FAQ",
                    "menu": "Menu"
                },
                "title": "Button",
                "font": {
                    "weight": {
                        "label": "Font weight",
                        "light": "Light",
                        "standard": "Standard",
                        "bold": "Bold"
                    },
                    "transform": {
                        "label": "Text transform",
                        "standard": "Standard",
                        "uppercase": "Uppercase"
                    },
                    "family": {
                        "label": "Fonts"
                    }
                },
                "update": {
                    "config": "Config",
                    "page": "Page",
                    "add": "Create Block",
                    "preview": "Preview",
                    "menu": "Menu",
                    "publish": "Publish"
                },
                "label": {
                    "text": "Text",
                    "avatar": "Avatar",
                    "divider": "Divider",
                    "button": "Button",
                    "carousel": "Image carousel",
                    "countdown": "Count down",
                    "messaging": "Messaging",
                    "social": "Social",
                    "media_text": "Media And Text",
                    "form": "Form",
                    "vcard": "Vcard",
                    "faq": "FAQ",
                    "html": "Html",
                    "music": "Music",
                    "video": "Video",
                    "map": "Maps",
                    "product": "Shop Product",
                    "page": "Add page"
                },
                "website": {
                    "label": "Website",
                    "placeholder": "https://"
                },
                "icon": {
                    "title": "Select icon"
                },
                "content": {
                    "label": "Button text",
                    "title": {
                        "title": "Button",
                        "label": "Button title",
                        "placeholder": "For example: button title",
                        "required": "You have not entered a title"
                    },
                    "description": {
                        "label": "Button description",
                        "placeholder": "For example: button description"
                    },
                    "action": {
                        "home": "Home",
                        "listBio": "List Bio",
                        "add": "Add Bio",
                        "faq": "FAQ",
                        "menu": "Menu",
                        "alignment": {
                            "top": "Top",
                            "center": "Center",
                            "bottom": "Bottom"
                        },
                        "group": {
                            "website": "Website",
                            "internal": "Choose internal page",
                            "call": "Enter your phone number",
                            "sms": "Enter your phone number",
                            "email": "Enter your email address",
                            "contact": "Personal information",
                            "block": "Block"
                        },
                        "message": {
                            "label": "Text message",
                            "placeholder": "Text message"
                        },
                        "email": {
                            "label": "Email",
                            "button": "Send email",
                            "placeholder": "example@xem.link",
                            "subject.placeholder": "Subject"
                        },
                        "value": {
                            "subject": {
                                "label": "Subject",
                                "placeholder": "Subject"
                            },
                            "nonePage": "- No page -",
                            "pageLoading": "Page loading ...",
                            "label": "Action",
                            "title": "Action Title",
                            "gotoBlock": "Go to block",
                            "emailSignup": "Email signup",
                            "embeddedPage": "Embedded page"
                        },
                        "title": {
                            "action": "Action",
                            "internal": "Internal"
                        },
                        "label": {
                            "phone": "Phone number",
                            "title": "Action",
                            "website": "https://"
                        },
                        "placeholder": {
                            "phone": "Phone number",
                            "website": "https://"
                        },
                        "website.label": "Website",
                        "call.label": "Call",
                        "internal.label": "Internal page",
                        "sms.label": "Send Sms",
                        "email.label": "Send email",
                        "contact": "Save contact",
                        "gotoBlock": "Go to block",
                        "emailSignup": "Email signup",
                        "embeddedPage": "Embedded page",
                        "new_window": "Open in new window"
                    }
                },
                "shadow": {
                    "title": "Shadow",
                    "x_offset_left": "X offset left",
                    "y_offset_left": "Y offset left",
                    "blur": "Blur",
                    "spread": "Spread",
                    "transparency": "Transparency",
                    "color": "Color",
                    "none": "No"
                },
                "radius": {
                    "label": "Radius",
                    "top_left": "Top left",
                    "top_right": "Top right",
                    "bottom_left": "Bottom left",
                    "bottom_right": "Bottom right"
                },
                "padding": {
                    "label": "Padding",
                    "top": "Padding top",
                    "bottom": "Padding bottom",
                    "left": "Padding left",
                    "right": "Padding right"
                },
                "margin": {
                    "label": "Margin",
                    "top": "Margin top",
                    "bottom": "Margin bottom",
                    "left": "Margin left",
                    "right": "Margin right"
                },
                "settings": {
                    "block": {
                        "name": {
                            "label": "Block name",
                            "placeholder": "Enter block name"
                        },
                        "description": "Naming a block simplifies its search in the archive and allows you to create links to it in other blocks",
                        "on": "Set block name",
                        "backgroundColor": "Background block color",
                        "hidden": "Hide block"
                    },
                    "sheet": {
                        "description": "Sheet settings description",
                        "on": "Set sheet name",
                        "name": {
                            "label": "Sheet name",
                            "placeholder": "Enter sheet name"
                        },
                        "spreadsheet_Id": {
                            "label": "Spreadsheet Id",
                            "placeholder": "Enter spreadsheet Id"
                        },
                        "client_email": {
                            "label": "Google client_email",
                            "placeholder": "Enter google client_email"
                        },
                        "private_key": {
                            "label": "Google private_key",
                            "placeholder": "Enter google private_key"
                        }
                    }
                }
            },
            "submit": {
                "FormSetting.label": "Conversion event code",
                "content.label": "Button text",
                "thankYou.label": "Page Thank You",
                "success": {
                    "label": "Thank you page.",
                    "popup": "Default popup",
                    "thank": "Another page",
                    "title": {
                        "label": "Success title",
                        "placeholder": "Enter success title"
                    },
                    "message": {
                        "label": "Success message",
                        "placeholder": "Enter success message"
                    }
                },
                "thankpage": {
                    "label": "Thank you URL",
                    "placeholder": "Enter thank you URL"
                },
                "event": {
                    "title": "Conversion: Facebook / TikTok / GA",
                    "no": "Not selected",
                    "completeRegistration": "Complete registration",
                    "purchase": "Purchase",
                    "lead": "Lead",
                    "submitForm": "Submit Form",
                    "completePayment": "Complete Payment"
                },
                "currency": {
                    "label": "Currency",
                    "placeholder": "Enter currency"
                },
                "google_uid": {
                    "label": "Google UID",
                    "placeholder": "For example: UA-86097XXX-1 hoặc G-XXXXXXX"
                },
                "google_ads_id": {
                    "label": "Google Ads ID",
                    "placeholder": "For example: AW-868647XXX"
                },
                "facebook_pixel": {
                    "label": "Facebook pixel ID",
                    "placeholder": "For example: 1540856564722XXX"
                },
                "google_tag_id": {
                    "label": "Google Tag Manager ID",
                    "placeholder": "For example: GTM-XAVNXXX"
                },
                "tiktok_pixel": {
                    "label": "TikTok Pixel ID",
                    "placeholder": "For example: BLSRA1AA1AAA1AAAAAXXX"
                },
                "purchase": {
                    "label": "Conversion value",
                    "placeholder": "For example: 600000"
                },
                "eventId": {
                    "label": "Google Ads conversion code",
                    "placeholder": "Enter the conversion ID (eg: 868686XXX)"
                },
                "eventLabel": {
                    "label": "Google Ads conversion label",
                    "placeholder": "For example: XUa3CJEMrocHZHxxMMM"
                },
                "title": {
                    "label": "Button title",
                    "placeholder": "For example: Button title"
                }
            },
            "html": {
                "label": "Html",
                "title": "Html"
            },
            "link": "Link",
            "hidden_block.label": "Hide block",
            "vertical_indent.label": "Vertical indent",
            "block_name.label": "Set block name",
            "according_to_schedule.label": "Show according to schedule",
            "days_of_the_week.label": "Show by days of the week",
            "action.label": "action",
            "transparency.label": "Transparency",
            "header_color.label": "Header color",
            "subtitle_color.label": "Subtitle color",
            "background_color.label": "Background color"
        },
        "form": {
            "search.placeholder": "Form search",
            "app": {
                "group": {
                    "all": "All",
                    "social": "Social networks",
                    "messaging": "Messaging",
                    "music": "Music",
                    "travel": "Travel",
                    "shopping": "Shopping"
                }
            },
            "forms": {
                "edit": "Edit form",
                "delete": "Delete form",
                "optional": "Optional",
                "addons": {
                    "add": "Add-ons new",
                    "edit": "Edit add-on",
                    "delete": "Delete add-on"
                }
            },
            "display": {
                "label": "Display preview",
                "description.empty": "You don't have a description yet."
            },
            "page": {
                "content": {
                    "title": {
                        "placeholder": "For example: Page title",
                        "label": "Page title",
                        "description": "Displayed in the title of the browser tab and in search engine results"
                    },
                    "description": {
                        "placeholder": "For example: Meta-description",
                        "label": "Meta-description",
                        "description": "Displayed in search engine results under the link"
                    }
                },
                "html": {
                    "code": {
                        "label": "Code",
                        "placeholder": "Enter html code"
                    }
                },
                "edit": "Updated page",
                "create": "Created page",
                "config": {
                    "url": {
                        "label": "Url",
                        "placeholder": "Enter your alias"
                    },
                    "schema": {
                        "title": "Schema",
                        "label": "Schema",
                        "placeholder": "Enter your alias"
                    },
                    "title": "Config page",
                    "changeUrl": "Change Url",
                    "seo": "SEO settings",
                    "conversion": "Conversion event",
                    "qrcode": "QR code",
                    "background": "Page background",
                    "close": "Return pages",
                    "alignment": "Content alignment",
                    "duplicate": "Duplicate page",
                    "hide": "Hide page",
                    "inactive": "Hide page",
                    "active": "Publish page"
                }
            },
            "title": {
                "edit": "Edit page",
                "delete": "Delete page"
            },
            "btn": {
                "delete": "Delete page"
            }
        }
    }
};
